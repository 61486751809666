<template>
  <table class="tech-certificate-article-table" v-if="!$isMobile">
    <thead>
      <tr>
        <th>Артикул</th>
        <th>Наименование</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="articles.length">
      <tr v-for="a in articles" :key="a.id">
        <td>
          <span>
            {{ a.article }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ a.article }}
          </span>
        </td>
        <td>
          <span>
            {{ a.name }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ a.name }}
          </span>
        </td>
        <td class="no-wrap">
          <span
            class="_remove"
            @click="
              () => {
                $emit('removeArticle', a.id);
              }
            "
          >
            <img src="@/assets/icons/clear.svg" alt="Remove" />
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="tech-certificate-table-mobile table-mobile"
    ref="table"
    v-else-if="articles.length && $isMobile"
  >
    <div class="table-mobile-column" v-for="a in articles" :key="a.id">
      <span
        class="_remove"
        @click="
          () => {
            $emit('removeArticle', a.id);
          }
        "
      >
        <img src="@/assets/icons/clear.svg" alt="Remove" />
      </span>
      <div class="table-mobile-row">
        <span class="table-mobile__title tech-certificate-table-mobile__title">
          Артикул
        </span>
        <span class="table-mobile__value">
          {{ a.article }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title tech-certificate-table-mobile__title">
          Наим-ие
        </span>
        <span class="table-mobile__value">
          {{ a.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articles: {
      type: Array,
    },
  },
};
</script>
