<template>
  <section class="tech-certificate">
    <!-- title -->
    <h1 class="tech-certificate__title page__title">Техническое задание</h1>
    <!-- tech docs content -->
    <div class="tech-certificate-wrapper page-wrapper">
      <div class="tech-certificate-header">
        <span class="tech-certificate-header__title">
          Поиск<span v-if="!$isMobile"> документа</span>:
        </span>
        <div class="tech-certificate-header__buttons">
          <div class="tech-certificate-header__kp">
            <base-button
              type="link-button"
              :active="active == 1"
              @clicked="active = 1"
            >
              по <span class="upp" style="margin-left: 2px">кп</span>
            </base-button>
          </div>
          <div class="tech-certificate-header__vendor">
            <base-button
              type="link-button"
              :active="active == 2"
              @clicked="active = 2"
            >
              по артикулу
            </base-button>
          </div>
        </div>
      </div>
      <div class="tech-certificate-content">
        <div
          class="tech-certificate-article"
          :class="{ filled: articles.length }"
          v-if="active == 2"
        >
          <div class="tech-certificate-article-field article-row _kp">
            <div class="tech-certificate-article-field article-row-field">
              <base-input
                :id="'offerCreateConterparty'"
                :placeholder="'Артикул или наименование'"
                :search="true"
                :suggestionWidth="380"
                :addProduct="true"
                :suggestions="articlesSuggestions"
                @setSuggestion="article = $event.value"
                @clear="
                  (articleToSearch = ''),
                    (article = ''),
                    (articlesSuggestions = [])
                "
                @input="getArticles"
                v-model="articleToSearch"
              />
            </div>
            <div class="tech-certificate-article-add article-row-button">
              <base-button type="outlined" @clicked="addArticle">
                Добавить
              </base-button>
            </div>
          </div>
          <!-- table -->
          <tech-docs-table
            :articles="articles"
            @removeArticle="removeArticle"
          />
        </div>
        <div class="tech-certificate-kp">
          <div class="tech-certificate-kp-fields fields">
            <div
              class="tech-certificate-fields-row fields-row _number"
              v-if="active == 1"
            >
              <base-input
                :label="'№ КП'"
                :id="'techDocsKp'"
                :search="true"
                :addProduct="true"
                :suggestions="kpSuggestions"
                :suggestionWidth="380"
                @clear="(number = ''), (kpSuggestions = []), getOffers()"
                @input="getOffers"
                v-model="number"
              />
            </div>
            <div class="tech-certificate-fields-row fields-row _email">
              <base-input
                :label="'Электронная почта'"
                input-type="email"
                :id="'techEmail'"
                v-model="email"
              />
            </div>
            <div class="tech-certificate-type">
              <span class="tech-certificate-type__title">Тип</span>
              <div class="tech-certificate-types">
                <div class="base-check radio">
                  <input
                    type="radio"
                    id="techDocsKtru"
                    value="ktru"
                    v-model="docsType"
                  />
                  <label for="techDocsKtru" class="upp"> ктру </label>
                </div>
                <div class="base-check radio">
                  <input
                    type="radio"
                    id="techDocsTu"
                    value="krtu_gost"
                    v-model="docsType"
                  />
                  <label for="techDocsTu" class="upp"> ту </label>
                </div>
                <div class="base-check radio">
                  <input
                    type="radio"
                    id="techDocsKtrus"
                    value="krtu_avg"
                    v-model="docsType"
                  />
                  <label for="techDocsKtrus">
                    <span class="upp">ктру</span> сокр.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tech-certificate-buttons page-buttons _save">
      <div class="tech-certificate-buttons__send _save__button">
        <base-button type="filled" :disabled="disabledSubmit" @clicked="send">
          Отправить</base-button
        >
      </div>
      <div class="tech-certificate-buttons__download">
        <base-button
          type="outlined"
          :disabled="disabledSubmit"
          @clicked="download()"
          >Скачать</base-button
        >
      </div>
    </div>
  </section>
  <base-modal
    :open="openModal"
    :text="'Отправлено'"
    @close="openModal = false"
  />
</template>

<script>
import { useMeta } from "vue-meta";
import TechDocsTable from "@/components/techDocs/TechDocsTable.vue";
import { formatUrl } from "@/helpers/index";

export default {
  name: "TechDocs",
  components: {
    TechDocsTable,
  },
  data() {
    return {
      active: 1,
      number: "",
      techFieldsNumber: false,
      email: "",
      docsType: "ktru",
      techDocsArticle: "",
      article: "",
      articleToSearch: "",
      openModal: false,
      articles: [],
      kpSuggestions: [],
      articlesSuggestions: [],
    };
  },
  setup() {
    useMeta({ title: "Техническое задание" });
  },
  computed: {
    disabledSubmit() {
      if (this.active === 1) {
        return !this.number || !this.email || !this.docsType;
      }

      return !this.email || !this.docsType || !this.articles?.length || false;
    },
  },
  methods: {
    getOffers() {
      // general request
      this.kpSuggestions = [];
      let params = {
        [this.number && "search"]: this.number,
      };
      this.$api
        .get(`${this.$api.defaults.API}/offer`, { params })
        .then((res) => {
          // loop for get all fields values
          for (const o of res.data.results) {
            // get search suggestions
            if (this.number) {
              this.kpSuggestions.push({
                key: o.id,
                value: o.id,
              });
            }
          }
        })
        .catch((err) => new Error(err));
    },
    getArticles() {
      // general request
      this.articlesSuggestions = [];
      let params = {
        [this.articleToSearch && "search"]: this.articleToSearch,
      };
      this.$api
        .get(`${this.$api.defaults.API}/product`, { params })
        .then((res) => {
          // loop for get all fields values
          for (const o of res.data.results) {
            // get search suggestions
            if (this.articleToSearch) {
              this.articlesSuggestions.push({
                key: o.name,
                value: o.id,
              });
            }
          }
        })
        .catch((err) => new Error(err));
    },
    addArticle() {
      this.$store.dispatch("loading", true);
      this.$api
        .get(`${this.$api.defaults.API}/product/${this.article}`)
        .then((res) => {
          !this.articles.some((i) => i.id === res.data.id) &&
            this.articles.push(res.data);
          this.article = "";
          this.articleToSearch = "";
          this.articlesSuggestions = [];
          this.$store.dispatch("loading", false);
        });
    },
    async send() {
      const data = {
        send_to: this.email,
        tech_docs_format: this.docsType,
        articles: [],
      };

      // if with KP
      if (this.active === 1) {
        // get article details
        await this.$api
          .get(`${this.$api.defaults.API}/offer/${this.number}`)
          .then(async (res) => {
            const articles = await res?.data?.offer_products;
            for (const a of articles) {
              await data?.articles.push(a.product);
            }
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      } else {
        for (const a of this.articles) {
          data?.articles.push(a.id);
        }
      }
      // setd articles to email
      if (data.send_to && data.tech_docs_format && data.articles.length) {
        this.$store.dispatch("loading", true);
        this.$api
          .post(`${this.$api.defaults.API}/tech-docs/send-to-email`, data)
          .then(() => {
            (this.modalText = "Отправлено"), (this.openModal = true);
            this.$store.dispatch("loading", false);
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      }
    },
    async download() {
      const data = {
        tech_docs_format: this.docsType,
        articles: [],
      };

      // if with KP
      if (this.active === 1) {
        this.$store.dispatch("loading", true);
        // get article details
        await this.$api
          .get(`${this.$api.defaults.API}/offer/${this.number}`)
          .then(async (res) => {
            const fileName =
              (await this.docsType) === "ktru"
                ? "krtu_file"
                : this.docsType === "krtu_gost"
                ? "krtu_gost_file"
                : this.docsType === "krtu_avg"
                ? "krtu_avg_file"
                : "";

            window.open(formatUrl(res?.data[`${fileName}`]), "_blank");
            this.$store.dispatch("loading", false);
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      } else {
        for (const a of this.articles) {
          await data?.articles.push(a.id);
        }
      }

      // download file
      if (data.articles?.length) {
        this.$store.dispatch("loading", true);

        this.$api
          .post(`${this.$api.defaults.API}/tech-docs/download`, data)
          .then((res) => {
            window.open(formatUrl(res.data.url), "_blank");
            this.$store.dispatch("loading", false);
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      }
    },
    removeArticle(val) {
      const index = this.articles.findIndex((i) => i.id === val);
      this.articles.splice(index, 1);
    },
  },
};
</script>
