export const formatUrl = (url) => {
  if (url?.startsWith?.("https")) {
    return url;
  }
  return url?.replace("http", "https");
};

export const getCurrencySymbol = (offer) => {
  const priceType = offer?.price_type ?? offer ?? "standard";

  if (priceType?.toLowerCase() === "currency") {
    return `$`;
  }

  return `₽`;
};
